import Wechat from './wechat';
import DingTalk from './dingTalk'
import GovDingTalk from './govDingTalk'
import Native from './native';
import Android from './android';
import Ios from './ios';
import Harmony from './harmony';
import dsf from '../utils';
import Event from './Event'

const driveList = {
  Wechat,
  DingTalk,
  Native,
  Harmony,
  GovDingTalk,
  Android,
  Ios
}


export default {
  $driveList: driveList,
  $init(opt = {}) {
    let _opt = {
      driveList: [GovDingTalk, DingTalk, Wechat, Harmony, Android, Ios, Native],
      _dingTalk: {
        sdkUrl: "https://g.alicdn.com/dingding/dingtalk-jsapi/2.10.3/dingtalk.open.js",
      },
      _govDingTalk: {
        sdkUrl: "https://g.alicdn.com/gdt/jsapi/1.9.18/index.js",
      },
      _android: {
        sdkUrl: dsf.url.getWebPath("$/js/libs/cordova/android/cordova.js"),
      },
      _ios: {
        sdkUrl: dsf.url.getWebPath("$/js/libs/cordova/ios/cordova.js"),
      },
      _wechat: {
        sdkUrl: "https://res.wx.qq.com/open/js/jweixin-1.6.0.js",
      }
    }
    if (opt.driveList) {
      _opt.driveList = opt.driveList
      delete opt.driveList
    }
    dsf.mix(_opt, opt)
    let drive = {
      type: "web",
      init() {
        return Promise.resolve()
      }
    }
    for (let i = 0; i < _opt.driveList.length; i++) {
      if (_opt.driveList[i]?.isEnv()) {
        drive = _opt.driveList[i];
        break
      }
    }
    this.$type = drive.type
    this._register = this._register.bind(this)
    this._getFuncOpts = this._getFuncOpts.bind(this)
    this._errorMessage = this._errorMessage.bind(this)
    return drive.init(this._getFuncOpts({}, _opt), this)
      .then(() => {
        drive.$event = this.$event
        dsf.mix(this, { _onEventMappingNames: drive._onEventMappingNames || {} });
        dsf.mix(this, drive.methods);
        this.$inited = true;
      });
  },
  $type: null,
  $inited: false,
  $event: new Event(),
  _getFuncOpts(def = {}, opt = {}) {
    let envOpt = {}
    if (dsf.type(opt) !== 'object') throw '传入的参数需要是对象类型{xx:xx,_${env}:{xxx}}'
    for (const key in opt) {
      if (key.charAt(0, 1) !== '_') {
        envOpt[key] = opt[key]
      } else if (key == '_' + this.$type) {
        dsf.mix(envOpt, opt[key])
      }
    }
    return dsf.mix(def, envOpt)
  },
  _errorMessage(funcDesc) {
    return `${this.$type}环境没有实现[${funcDesc}]方法`
  },
  _register(keys, fn) {
    if (!keys) return
    if (keys.indexOf('.') < 0) {
      this[keys] = fn.bind(this)
      return
    }
    let keyMap = keys.split('.')
    let obj = this;
    let i = 0
    while (i < keyMap.length) {
      let key = keyMap[i]
      if (!obj[key]) {
        obj[key] = {}
      } else {
        obj = obj[key]
      }
    }
    obj[keyMap[i]] = fn.bind(this)
  },
  // 扫一扫
  scan(opt = {}) {
    return Promise.reject(this._errorMessage('扫一扫'))
  },
  // 拨打电话
  callPhone(opt = {}) {
    return new Promise((resolve, reject) => {
      try {
        let _opt = this._getFuncOpts(
          {
            phoneNumber: '', // 类型 qrCode 二维码  barCode 条形码 all 所有
          },
          opt
        )
        if (!_opt.phoneNumber) throw '请传入phoneNumber电话号码'
        window.location.href = `tel:${_opt.phoneNumber}`
      } catch (error) {
        reject(error)
      }
    })
  },
  // 新开页面
  open(opt = {}) {
    return new Promise((resolve, reject) => {
      let _opt = this._getFuncOpts(
        {
          url: '', // 网址
        },
        opt
      )
      if (!_opt.url) throw '请传入url'
      try {
        window.open(url)
      } catch (error) {
        reject(error)
      }
    })
  },
  // 设置 浏览器 title
  navigationSetTitle(opt = {}) {
    return new Promise((resolve, reject) => {
      try {
        let _opt = this._getFuncOpts(
          {
            title: '', // 网址
          },
          opt
        )
        window.document.title = _opt.title
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  // 下载文件
  downloadFiles(opt = {}) {
    return new Promise((resolve, reject) => {
      try {
        if (dsf.type(opt.files) == 'string') {
          opt.files = JSON.parse(opt.files)
        }
        let _opt = this._getFuncOpts(
          {
            files: [],
          },
          opt
        )
        if (!_opt.files.length) {
          console.warn('请传入opt.files')
          return reject('请传入opt.files')
        }
        let fileName = _opt.fileName
        if (!fileName) {
          fileName = _opt.files[0].originalFileName
        }
        let ids = _opt.files.map(ite => ite.id).join(',')
        var url = dsf.url.getWebPath("file/download");
        url = url + "?files=" + ids + "&zipName=" + fileName;
        var url = dsf.url.getWebPath("file/download");
        $(
          '<form action="' +
          url +
          '" method="get">' + // action请求路径及推送方法
          '<input type="text" name="files" value="' +
          ids +
          '"/>' + // id^fileName
          '<input type="text" name="zipName" value="' +
          (fileName || "") +
          '"/>' + // 压缩包名称
          "</form>"
        )
          .appendTo("body")
          .submit()
          .remove();
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
}