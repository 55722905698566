export default {
  type: "harmony",
  isEnv() {
    let {
      harmony
    } = dsf.client
    return harmony()
  },
  init(opt = {}) {
    try {
      return this.checkIsReady()
    } catch (error) {
      return Promise.reject(error)
    }
  },
  checkIsReady() {
    return new Promise((resolve, reject) => {
      var checkOut;
      var time = 0
      check()

      function check() {
        if (window.$HW) {
          resolve()
          if (checkOut) clearTimeout(checkOut)
          return
        } else if (time > 10) {
          if (checkOut) clearTimeout(checkOut)
          reject()
          return
        }
        time++
        checkOut = setTimeout(check, 500)
      }
    })
  },
  methods: {
    // 关闭开机画面
    hideSplashScreen() {
      return new Promise((resolve, reject) => {
        if (window.$HW.splash.dismiss) {
          window.$HW.splash.dismiss({}, function (args) {
            resolve(resolve)
          }, function (args) {
            reject(args)
          });
        }
      })
    },
    storage: {
      // 设置
      set(key, value) {
        return new Promise((resolve, reject) => {
          window.$HW.action.shareInsert({
            [key]: value
          }, () => {
            resolve()
          }, (e) => {
            reject(e)
          });
        })
      },
      // 获取
      get(key) {
        return new Promise((resolve, reject) => {
          window.$HW.action.shareGet({
            key,
          }, (args) => {
            resolve(args)
          }, (err) => {
            reject(err)
          });
        })
      },
      // 删除
      remove(key) {
        return new Promise((resolve, reject) => {
          window.$HW.action.shareDelete({
            key,
          }, () => {
            resolve()
          }, (e) => {
            reject(e)
          });
        })
      },
      // 清除
      claerAll() {
        return new Promise((resolve, reject) => {
          window.$HW.action.shareClear(() => {
            resolve()
          }, (e) => {
            reject(e)
          });
        })
      }
    }
  }
}